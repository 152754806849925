<template>
  <div>
    <div v-if="!isSelectCustomer" class="box">
      <div class="title">
        <span>生日自动祝福功能设置</span>
        <span class="right"><span style="color: red">*</span>为必填</span>
      </div>
      <div class="content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm"
          size="small"
        >
          <el-form-item label="客户范围" prop="receiverRangeKey">
            <el-radio-group v-model="ruleForm.receiverRangeKey" @change="radioChange">
              <el-radio v-for="(n, i) in customerCate" :key="i" :label="n.dictionaryValue">{{
                n.dictionaryName
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item prop="customers">
            <el-input size="small" v-model="ruleForm.customers"></el-input>
          </el-form-item> -->
          <el-form-item v-if="ruleForm.receiverRangeKey == 2" label="标签" prop="customers">
            <el-link :underline="false" type="primary" @click="selectTags">点击选择标签</el-link>
            <br />
            <el-tag
              v-for="tag in tags"
              :key="tag.name"
              size="small"
              closable
              :type="tag.type"
              @close="close(1, tag)"
            >
              {{ tag.name }}
            </el-tag>
          </el-form-item>
          <el-form-item v-if="ruleForm.receiverRangeKey == 3" label="渠道" prop="customers">
            <el-link :underline="false" type="primary" @click="selectchan">点击选择渠道</el-link>
            <br />
            <el-tag
              v-for="tag in chanTags"
              :key="tag.channelId"
              size="small"
              closable
              :type="tag.type"
              @close="close(2, tag)"
            >
              {{ tag.channelName }}
            </el-tag>
          </el-form-item>
          <el-form-item v-if="ruleForm.receiverRangeKey == 4" label="客户人数" prop="customers">
            <span>{{ customerList.length }}</span
            >人
            <el-link :underline="false" type="primary" @click="selectCust">点击选择客户</el-link>
          </el-form-item>
          <!-- <el-form-item label="消息模板类型" prop="templateCategoryId">
            <el-select
              style="width: 400px"
              v-model="ruleForm.templateCategoryId"
              placeholder="请选择"
              @change="cateChange"
            >
              <el-option
                v-for="(item, i) in cateList"
                :key="i"
                :label="item.categoryName"
                :value="item.categoryId"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="消息模板名称" prop="templateId">
            <el-select
              v-model="ruleForm.templateId"
              style="width: 400px"
              placeholder="请选择"
              @change="nameChange"
            >
              <el-option
                v-for="(item, i) in nameList"
                :key="i"
                :label="item.templateName"
                :value="item.templateId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="消息模板内容" prop="templateContent">
            <el-input
              v-model="ruleForm.templateContent"
              readonly
              style="width: 400px"
              type="textarea"
              :rows="8"
              maxlength="500"
              show-word-limit
              placeholder="请输入话术内容"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="发送时间" prop="delaySendTime">
            <el-select v-model="ruleForm.delaySendTime" placeholder="请选择">
              <el-option label="09:00" value="09:00"></el-option>
              <el-option label="10:00" value="10:00"></el-option>
              <el-option label="11:00" value="11:00"></el-option>
              <el-option label="12:00" value="12:00"></el-option>
              <el-option label="13:00" value="13:00"></el-option>
              <el-option label="14:00" value="14:00"></el-option>
              <el-option label="15:00" value="15:00"></el-option>
              <el-option label="16:00" value="16:00"></el-option>
              <el-option label="17:00" value="17:00"></el-option>
              <el-option label="18:00" value="18:00"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button size="small" type="primary" @click="ok">保存</el-button>
        </div>
      </div>
    </div>
    <SelectCustomer
      v-if="isSelectCustomer"
      :checked-data="ruleForm.customersPlan"
      @cancel="isSelectCustomer = false"
      @customerOk="customerOk"
    />
    <SelectChan
      :chan-tags="chanTags"
      :dialog-visible="dialogVisible"
      @cancel="dialogVisible = false"
      @ok="chanOk"
    />
    <setTagMangeVue
      :visible="visible"
      :value="ruleForm.receiverRangeValue"
      :tags-data="tagsData"
      :tag-loading="tagLoading"
      @ok="tagOk"
      @cancel="tagCancel"
    />
  </div>
</template>

<script>
import SelectCustomer from '@/views/components/selectCustomer'
import SelectChan from '@/views/components/selectChan'
import setTagMangeVue from '@/views/components/selectTags'
import {
  messageTemplateCategory,
  chooseMessTemplateByBirthday,
  saveBirthday,
  queryChildsById,
  getWishPlan,
  getTagTree,
  getChannelPage,
} from '@/api'

export default {
  components: {
    SelectCustomer,
    SelectChan,
    setTagMangeVue,
  },
  data() {
    return {
      ruleForm: {
        receiverRangeKey: '1',
        templateId: '',
        receiverRangeValue: [],
        customersPlan: [],
        templateContent: '',
      },
      rules: {
        receiverRangeKey: [{ required: true, message: '请选择', trigger: 'change' }],
        templateContent: [{ required: true, message: '请选择', trigger: 'change' }],
        templateId: [{ required: true, message: '请选择', trigger: 'change' }],
        templateCategoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        customers: [{ required: true, message: '请选择', trigger: 'change' }],
        delaySendTime: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      tags: [],
      chanTags: [],
      radio: '1',
      showTime: false,
      isSelectCustomer: false,
      dialogVisible: false,
      selectList: [],
      visible: false,
      tagLoading: false,
      customerList: [],
      cateList: [],
      nameList: [],
      customerCate: [],
      tagsData: [],
      chanData: [],
    }
  },
  mounted() {
    this.messageTemplateCategory()
    this.queryChildsById()
    this.chooseMessTemplateByBirthday()
    this.getInfo()
  },
  methods: {
    radioChange() {
      this.ruleForm.receiverRangeValue = []
      this.ruleForm.customersPlan = []
      this.tags = []
      this.customerList = []
      this.chanTags = []
      this.ruleForm.customers = ''
    },
    getTags() {
      getTagTree({ data: { tagValueIds: [] } }).then((res) => {
        this.tagsData = [...res.data]
        this.ruleForm.receiverRangeValue.forEach((v) => {
          this.filterData(res.data, v)
        })
      })
    },
    filterData(data, v) {
      data.forEach((e) => {
        if (e.id === v) {
          this.tags.push(e)
        } else {
          if (e.children) {
            this.filterData(e.children, v)
          }
        }
      })
    },
    getInfo() {
      getWishPlan({
        data: {
          isAll: 1,
        },
      }).then((res) => {
        const data = res.data
        if (data) {
          data.receiverRangeKey = data.receiverRangeKey + ''
          // data.receiverRangeValue = JSON.parse(data.receiverRangeValue);
          if (data.receiverRangeKey === '4') {
            this.customerList = data.receiverRangeValue
          }

          if (data.receiverRangeKey === '3') {
            getChannelPage({ data: {}, pageNumber: 1, pageSize: 10 }).then((ress) => {
              this.chanData = ress.data.content
              data.receiverRangeValue.forEach((v) => {
                this.chanData.forEach((e) => {
                  if (e.id === v) {
                    this.chanTags.push(e)
                  }
                })
              })
            })
          }
          // this.cateChange(data.templateCategoryId);
          data.delaySendTime = data.sendTime
          this.ruleForm = data
          if (data.receiverRangeKey === '2') {
            this.getTags()
          }
          this.$set(this.ruleForm, 'customers', 1)
        }
      })
    },
    queryChildsById() {
      queryChildsById({
        data: { key: 'message_scope' },
      }).then((res) => {
        this.customerCate = res.data.childs
      })
    },
    messageTemplateCategory() {
      messageTemplateCategory({ data: {} }).then((res) => {
        this.cateList = res.data
      })
    },
    selectCust() {
      this.isSelectCustomer = true
    },
    selectchan() {
      this.dialogVisible = true
    },
    selectTags() {
      this.visible = true
    },
    chooseMessTemplateByBirthday() {
      chooseMessTemplateByBirthday({
        data: '',
      }).then((res) => {
        this.nameList = res.data
      })
    },
    nameChange(e) {
      this.nameList.forEach((v) => {
        if (v.templateId === e) {
          this.ruleForm.templateContent = v.templateContent
        }
      })
    },
    close(flag, row) {
      flag = Number(flag)
      if (flag === 1) {
        for (let i = 0; i < this.tags.length; i++) {
          if (this.tags[i].id === row.id) {
            this.tags.splice(i, 1)
          }
          this.ruleForm.receiverRangeValue = this.tags.map((i) => i.id)
        }
      }
      if (flag === 2) {
        for (let i = 0; i < this.chanTags.length; i++) {
          if (this.chanTags[i].id === row.id) {
            this.chanTags.splice(i, 1)
          }
          this.ruleForm.receiverRangeValue = this.chanTags.map((i) => i.id)
        }
      }
    },
    tagOk(e) {
      this.ruleForm.customers = '1'
      this.tags = [...e]
      this.visible = false
      this.ruleForm.receiverRangeValue = []
      this.tags.forEach((e) => {
        this.ruleForm.receiverRangeValue.push(e.id)
      })
    },
    tagCancel() {
      this.visible = false
    },
    chanOk(e) {
      this.dialogVisible = false
      this.ruleForm.customers = '1'
      this.chanTags = e
      this.ruleForm.receiverRangeValue = []
      this.chanTags.forEach((e) => {
        this.ruleForm.receiverRangeValue.push(e.id)
      })
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const obj = { ...this.ruleForm }
          delete obj.customers
          delete obj.templateName
          delete obj.templateCode
          delete obj.pageTotal
          const data = { ...obj, planType: 5, receiverType: 1 }
          saveBirthday({
            data,
          }).then((res) => {
            if (res.success) {
              this.$message({
                message: '保存成功',
                type: 'success',
              })
              this.$router.go(-1)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    customerOk(val) {
      this.isSelectCustomer = false
      this.ruleForm.customers = val && val.length > 0 ? '1' : ''
      if (val) {
        this.ruleForm.receiverRangeValue = []
        this.ruleForm.customersPlan = []
        this.customerList = []
        val.forEach((e) => {
          this.ruleForm.receiverRangeValue.push(e.customerId)
          this.ruleForm.customersPlan.push({
            customerId: e.customerId,
            content: JSON.stringify({
              channelName: e.channelName,
              customerName: e.customerName,
              cityName: e.cityName,
            }),
          })
          this.customerList.push(e)
        })
      }
    },
    cancel() {
      this.$router.go(-1)
    },
    handleClose(tag) {},
  },
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #eee;
  background-color: #fff;
}
.content {
  padding: 20px;
  border: none;

  .footer {
    text-align: center;
  }
}
.title {
  padding: 10px 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  .right {
    font-size: 14px;
    color: #999;
  }
}
.el-tag {
  margin-right: 10px;
}
</style>
